.Step {
    display: flex;
    margin: 4px 0;
    position: relative;
}

.Step>div {
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

}

.Step .Time {
    min-width: 140px;
    background-color: white;
    z-index: 6;
    color: #555;
    margin-right: 4px;
    line-height: 48px;
}

.Step .Details {
    justify-content: flex-end;
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    color: #666;
    background-color: white;
}

.Step .Details .Analytics{
    width: 100%;
    display: flex;
    padding-left: 8px;
    margin: auto;
}

.Details div span {
    padding: 0 4px 0 2px;
}
.Details .Number{
    flex-grow: 1;
    text-align: left;
}
.Menu {
    position: absolute;
    background-color: #fff;
    z-index: 10;
    color: #000;
    text-align: left !important;
    border-radius: 6px;
    border: 1px solid #eee;
    min-width: 50%;
    padding: 6px 12px
}

.Menu .Edit {
    display: flex;
}

.Menu .Edit input {
    width: 60px;
    margin-right: 4px;
    text-align: center;
    font-size: 15px;
    padding: 10px 0;
}

.Menu .Edit select {
    font-size: 15px;
    padding: 10px 0;
    text-align: center;
}

.Step .Selected {
    background-color: #3f51b5; 
    color: white;
}