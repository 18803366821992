.Modal {
    position: absolute;
    width: 300px;
    background-color: white;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 150px;
    border-radius: 4px;
    padding: 16px;
    text-align: center;
}