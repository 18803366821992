select, input{
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 20px;
}


.Remove{
    border: 1px solid rgb(229, 57, 53) ;
    background-color: transparent;
    border-radius: 4px;
    padding: 8px;
    margin: auto 4px;
    max-height: 36px;
    line-height: 18px;
    color:  rgb(229, 57, 53);
}