.MessageBlock {
    min-height: 87vh;
    display: flex;
    height: 100%;
    flex-direction: column;
}

.Message {
    width: 100%;
    border-radius: 4px;
    padding: 6px;
    resize: none;
    height: 100%;
    border-color: #eee;
}

.Loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
}

.Title{
    padding: 16px 16px 0 16px;

}

.Sequence,.Step{
    padding: 0 4px;
    font-size: 14px;
    padding: 6px 8px;
}

.Segmants {
    max-width: 200px;
    margin: 0 12px;
    font-size: 14px;
    padding: 6px 8px;
}