.Header {
    display: flex;
    width: 100%;
}
.Name{
    flex-grow: 1;
}

.Name input{
    max-width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0 8px;
    margin-right: 8px;
    max-width: 100%;
}

.Analytics {
    display: flex;
    color: #444;
    margin: auto;
}


.Analytics span{
    padding: 0 4px 0 2px;
}

 .Add{
    border-radius: 8px;
    padding: 10px 12px;
    text-align: center;
    cursor: pointer;
    background-color: white;
    width: 140px;
    font-weight: thin;
    position: relative;
}
.Outside {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: #eee
}

.Options {
    display: flex;
}

.Options > div{
    flex-grow: 1;
}